// TODO: Consolidate disclosures across all UI modules and pages
const paymentDetailNoInterest = "<p><sup>1</sup><strong><u>Suggested Payment Details:</u></strong> The suggested monthly payment requires placing your purchase on a promotional credit plan, and it may be higher than the required minimum monthly payment on your billing statement. The following assumptions were made regarding the suggested monthly payment: it equals the purchase amount (excluding taxes and shipping costs) divided by the number of months in the promotional period, rounded up to the nearest dollar; you make equal payments during the promotional period; you make all payments on time; and you currently do not and will not have any other balance on your Account during the promotional period.<sup>2</sup></p>";
const paymentDetailPromotionalRate = "<p><sup>3</sup><strong><u>Suggested Payment Details:</u></strong> The suggested monthly payment requires placing your purchase on a promotional credit plan with equal monthly payments. The following assumptions were made regarding the suggested monthly payment: it equals the purchase amount (excluding taxes and shipping costs) divided by the number of months in the promotional period, rounded up to the nearest dollar; you make equal payments during the promotional period; and you make all payments on time.<sup>4</sup></p>";
const supPlacementDisclosures = {
  PAYMENT_DETAILS_PROMOTIONAL_NO_INTEREST: "<p>#supPlacement#<strong><u>Suggested Payment Details:</u></strong> The suggested monthly payment requires placing your purchase on a promotional credit plan, and it may be higher than the required minimum monthly payment on your billing statement. The following assumptions were made regarding the suggested monthly payment: it equals the purchase amount (excluding taxes and shipping costs) divided by the number of months in the promotional period, rounded up to the nearest dollar; you make equal payments during the promotional period; you make all payments on time; and you currently do not and will not have any other balance on your Account during the promotional period.#matchSup#</p>",
  NO_INTEREST_PLAN: "<p>#supPlacement#<strong><u>Promotional Credit Plan Details:</u></strong> Promotional No Interest (Waived Interest, Payment Required Plans): Interest will be charged to your Account from the end of the promotional period on any remaining promotional plan balance. <strong>Minimum payments are required for each Credit Plan. Minimum payments are NOT guaranteed to pay the promotional plan balance within the promotional period.</strong> For Your Tuition Solution Credit Accounts. Valid for single transaction only. As of August 2020, terms for new Accounts: Purchase APR of 26.99%. Minimum interest charge is $1.00 per Credit Plan in any billing period in which interest is due. Existing Account holders: see your Credit Account agreement for Account terms.*</p>",
  PAYMENT_DETAILS_PROMOTIONAL_PLANS: "<p>#supPlacement#<strong><u>Suggested Payment Details:</u></strong> The suggested monthly payment requires placing your purchase on a promotional credit plan with equal monthly payments. The following assumptions were made regarding the suggested monthly payment: it equals the purchase amount (excluding taxes and shipping costs) divided by the number of months in the promotional period, rounded up to the nearest dollar; you make equal payments during the promotional period; and you make all payments on time.#matchSup#</p>",
  PROMOTIONAL_RATE_PLAN: "<p>#supPlacement#<strong><u>Promotional Credit Plan Details:</u></strong> Promotional Rate Plan (Low APR, Equal Payment Plans): Interest will be charged to your Account at the reduced APR from the purchase date through the end of the promotional period. After the promotional plan expiration date, the purchase APR will apply. Minimum payments are required for each Credit Plan. For Your Tuition Solution Credit Accounts. Valid for single transaction only. As of August 2020, terms for new Accounts: Purchase APR of 26.99%. Minimum interest charge is $1.00 per Credit Plan in any billing period in which interest is due. Existing Account holders: see your Credit Account agreement for Account terms.*</p>",
  ALL_PROMOTIONAL_PLANS: "<p>#supPlacement#<strong><u>All Promotional Plans:</u></strong> For Your Tuition Solution Credit Accounts. Valid for single transaction only. As of August 2020, terms for new Accounts: Purchase APR of 26.99%. Minimum interest charge is $1.00 per Credit Plan in any billing period in which interest is due. Existing Account holders: see your Credit Account agreement for Account terms. <br/><br/> Credit Account offers are subject to credit approval. <br/>Your Tuition Solution Credit Accounts are issued by Comenity Capital Bank.</p>",
  LC_PLANS: "<p>#supPlacement#<strong><u>Installment Loans:</u></strong> Installment Loans are issued by LendingClub Bank, N.A., Member FDIC, Equal Housing Lender (“LendingClub Bank”). LendingClub Bank offers multiple Installment Loan plans, the availability of which varies by provider. Not all plans may be available through your selected provider. Credit terms depend upon credit score and other key financing characteristics, including but not limited to the amount financed, loan term length, and your credit usage and history.​</p>"
};
const disclosuresNew = {
  LC_PLANS:
    "<p>#supPlacement# <strong>Installment Loans:</strong> Installment Loans are issued by LendingClub Bank, N.A., Member FDIC, Equal Housing Lender (“LendingClub Bank”). LendingClub Bank offers multiple Installment Loan plans, the availability of which varies by provider. Not all plans may be available through your selected provider. Credit terms depend upon credit score and other key financing characteristics, including but not limited to the amount financed, loan term length, and your credit usage and history.​</p>",
  NO_INTEREST_PLAN:
    "<p>#supPlacement#<strong>Promotional Credit Plan Details:</strong> Waived Interest, Payment Required. No Interest for 6, or 12 months. After that, purchase APR of 32.99%. On purchases of $499 for 6 months, or $999 for 12 months made with your Lending Club Patient Solutions Credit Card. Interest will be charged to your Account from the end of the promotional period on any remaining promotional plan balance. Minimum payments are required for each Credit Plan. Minimum payments are NOT guaranteed to pay the promotional plan balance within the promotional period.*</strong></p>",
  PROMOTIONAL_RATE_PLAN:
    "<p>#supPlacement#<strong>Promotional Credit Plan Details:</strong> Low APR, Equal Payment. 17.90, 18.90, 19.90, or 20.90% APR for 24, 36, 48 or 60 months. After that, purchase APR of 32.99%. On purchases of $1000 or more for 24 months, $4000 or more for 36 months, $6,000 or more for 48 months, and $8,000 or more for 60 months made with your Lending Club Patient Solutions Credit Card. Interest will be charged to your Account at the reduced APR from the purchase date through the end of the promotional period. After the promotional plan expiration date, the purchase APR will apply. Minimum payments are required for each Credit Plan.*</p>",
  PAYMENT_DETAILS_PROMOTIONAL_NO_INTEREST:
    "<p>#supPlacement#<strong>Suggested Payment Details:</strong> The suggested monthly payment requires placing your purchase on a promotional credit plan, and it may be higher than the required minimum monthly payment on your billing statement. The following assumptions were made regarding the suggested monthly payment: it equals the purchase amount (excluding taxes and shipping costs) divided by the number of months in the promotional period, rounded up to the nearest dollar; you make 6 or 12 equal payments during the promotional period; you make all payments on time; and you currently do not and will not have any other balance on your Account during the promotional period.#matchSup#</p>",
  PAYMENT_DETAILS_PROMOTIONAL_PLANS:
    "<p>#supPlacement#<strong>Suggested Payment Details:</strong> The suggested monthly payment requires placing your purchase on a promotional credit plan with equal monthly payments. The following assumptions were made regarding the suggested monthly payment: it equals the purchase amount (excluding taxes and shipping costs) divided by the number of months in the promotional period, rounded up to the nearest dollar; you make 24, 36, 48, or 60 equal payments during the promotional period; and you make all payments on time.#matchSup#</p>",
  ALL_PROMOTIONAL_PLANS:
    "<p>**<strong>All Promotional Plans:</strong> Valid for single transaction only. As of 11/2024, terms for new Accounts: Purchase APR of 32.99%. Minimum interest charge is $3 in any billing period in which interest is due. A fee of $2.99 per month may apply for each paper billing statement mailed to you. You can avoid this fee by enrolling in paperless statements. Existing Cardholders: see your credit card agreement for Account terms.</p>",
  ALL_PROMOTIONAL_PLANS_ADDITIONAL: "<p> Credit Account offers are subject to credit approval. <br/>Your Tuition Solution Credit Accounts are issued by Comenity Capital Bank.</p>",
};
const changeDisclosures = {
  PAYMENT_DETAILS_PROMOTIONAL_NO_INTEREST: paymentDetailNoInterest,
  PAYMENT_DETAILS_PROMOTIONAL_PLANS: paymentDetailPromotionalRate,
  NO_INTEREST_PLAN: "<p><strong><u><sup>2</sup>Promotional Credit Plan Details:</u></strong> Promotional No Interest (Waived Interest, Payment Required Plans): Interest will be charged to your Account from the end of the promotional period on any remaining promotional plan balance. <strong>Minimum payments are required for each Credit Plan. Minimum payments are NOT guaranteed to pay the promotional plan balance within the promotional period.</strong> For Your Tuition Solution Credit Accounts. Valid for single transaction only. As of August 2020, terms for new Accounts: Purchase APR of 26.99%. Minimum interest charge is $1.00 per Credit Plan in any billing period in which interest is due. Existing Account holders: see your Credit Account agreement for Account terms.*</p>",
  PROMOTIONAL_RATE_PLAN: "<p><strong><u><sup>4</sup>Promotional Credit Plan Details:</u></strong> Promotional Rate Plan (Low APR, Equal Payment Plans): Interest will be charged to your Account at the reduced APR from the purchase date through the end of the promotional period. After the promotional plan expiration date, the purchase APR will apply. Minimum payments are required for each Credit Plan. For Your Tuition Solution Credit Accounts. Valid for single transaction only. As of August 2020, terms for new Accounts: Purchase APR of 26.99%. Minimum interest charge is $1.00 per Credit Plan in any billing period in which interest is due. Existing Account holders: see your Credit Account agreement for Account terms.*</p>",
  ALL_PROMOTIONAL_PLANS: "<p><strong>*<u>All Promotional Plans:</u></strong> For Your Tuition Solution Credit Accounts. Valid for single transaction only. As of August 2020, terms for new Accounts: Purchase APR of 26.99%. Minimum interest charge is $1.00 per Credit Plan in any billing period in which interest is due. Existing Account holders: see your Credit Account agreement for Account terms. <br/><br/> Credit Account offers are subject to credit approval. <br/>Your Tuition Solution Credit Accounts are issued by Comenity Capital Bank.</p>",
  LC_PLANS: "<p><strong><sup>1</sup><u>Installment Loans:</u></strong> Installment Loans are issued by LendingClub Bank, N.A., Member FDIC, Equal Housing Lender (“LendingClub Bank”). LendingClub Bank offers multiple Installment Loan plans, the availability of which varies by provider. Not all plans may be available through your selected provider. Credit terms depend upon credit score and other key financing characteristics, including but not limited to the amount financed, loan term length, and your credit usage and history.​</p>"
};

module.exports = {
  FIXED_RATE_PLAN_UNITY_FULL_ROLLOUT: "<p>Installment Loans are issued by LendingClub Bank, N.A., Member FDIC, Equal Housing Lender (“LendingClub Bank”). LendingClub Bank offers multiple Installment Loan plans, the availability of which varies by provider. Not all plans may be available through your selected provider. Credit terms depend upon credit score and other key financing characteristics, including but not limited to the amount financed, loan term length, and your credit usage and history.</p>",
  NO_INTEREST_PLAN: "<p><strong><u>Promotional Credit Plan Details:</u></strong> Promotional No Interest (Waived Interest, Payment Required Plans): Interest will be charged to your Account from the end of the promotional period on any remaining promotional plan balance. <strong>Minimum payments are required for each Credit Plan. Minimum payments are NOT guaranteed to pay the promotional plan balance within the promotional period.</strong> For Your Tuition Solution Credit Accounts. Valid for single transaction only. As of August 2020, terms for new Accounts: Purchase APR of 26.99%. Minimum interest charge is $1.00 per Credit Plan in any billing period in which interest is due. Existing Account holders: see your Credit Account agreement for Account terms. <br/><br/>Credit Account offers are subject to credit approval. <br/> Your Tuition Solution Credit Accounts are issued by Comenity Capital Bank.</p>",
  PROMOTIONAL_RATE_PLAN: "<p><strong><u>Promotional Credit Plan Details:</u></strong> Promotional Rate Plan (Low APR, Equal Payment Plans): Interest will be charged to your Account at the reduced APR from the purchase date through the end of the promotional period. After the promotional plan expiration date, the purchase APR will apply. Minimum payments are required for each Credit Plan. For Your Tuition Solution Credit Accounts. Valid for single transaction only. As of August 2020, terms for new Accounts: Purchase APR of 26.99%. Minimum interest charge is $1.00 per Credit Plan in any billing period in which interest is due. Existing Account holders: see your Credit Account agreement for Account terms.<br/><br/> Credit Account offers are subject to credit approval.<br />Your Tuition Solution Credit Accounts are issued by Comenity Capital Bank.</p>",
  MONTHLY_PAYMENT_NO_INTEREST: "<p><strong><u>Suggested Payment Details:</u></strong> The suggested monthly payment requires placing your purchase on a promotional credit plan, and it may be higher than the required minimum monthly payment on your billing statement. The following assumptions were made regarding the suggested monthly payment: it equals the purchase amount (excluding taxes and shipping costs) divided by the number of months in the promotional period, rounded up to the nearest dollar; you make equal payments during the promotional period; you make all payments on time; and you currently do not and will not have any other balance on your Account during the promotional period.<sup>1</sup></p><p><sup>1</sup><strong><u>Promotional Credit Plan Details:</u></strong> Promotional No Interest (Waived Interest, Payment Required Plans): Interest will be charged to your Account from the end of the promotional period on any remaining promotional plan balance. <strong>Minimum payments are required for each Credit Plan. Minimum payments are NOT guaranteed to pay the promotional plan balance within the promotional period.</strong> For Your Tuition Solution Credit Accounts. Valid for single transaction only. As of August 2020, terms for new Accounts: Purchase APR of 26.99%. Minimum interest charge is $1.00 per Credit Plan in any billing period in which interest is due. Existing Account holders: see your Credit Account agreement for Account terms. <br/><br/>Credit Account offers are subject to credit approval. <br/> Your Tuition Solution Credit Accounts are issued by Comenity Capital Bank.</p>",
  MONTHLY_PAYMENT_PROMOTIONAL_RATE: "<p><strong><u>Suggested Payment Details:</u></strong> The suggested monthly payment requires placing your purchase on a promotional credit plan with equal monthly payments. The following assumptions were made regarding the suggested monthly payment: it equals the purchase amount (excluding taxes and shipping costs) divided by the number of months in the promotional period, rounded up to the nearest dollar; you make equal payments during the promotional period; and you make all payments on time.<sup>1</sup></p><p><sup>1</sup><strong><u>Promotional Credit Plan Details:</u></strong> Promotional Rate Plan (Low APR, Equal Payment Plans): Interest will be charged to your Account at the reduced APR from the purchase date through the end of the promotional period. After the promotional plan expiration date, the purchase APR will apply. Minimum payments are required for each Credit Plan. For Your Tuition Solution Credit Accounts. Valid for single transaction only. As of August 2020, terms for new Accounts: Purchase APR of 26.99%. Minimum interest charge is $1.00 per Credit Plan in any billing period in which interest is due. Existing Account holders: see your Credit Account agreement for Account terms.<br/><br/> Credit Account offers are subject to credit approval.<br />Your Tuition Solution Credit Accounts are issued by Comenity Capital Bank.</p>",
  MONTHLY_PAYMENT_PROMOTIONAL_PLANS: `
  <p>
  <b>Promotional No- Interest, Waived  Interest Payment Required Plans</b> as referred to in the Comenity Capital Bank account agreement. This estimated monthly payment reflects the promotional plan balance divided by the number of months in the promotional period, rounded up to the nearest dollar. If you pay this amount each month, you should pay off the promotional plan purchase amount within the promotional period. The promotional period may end prior to the due date of the final billing period; see statement for promotional plan details. <b>This estimated monthly payment will NOT be the same as the required minimum payment reflected on your monthly statement;</b> if you pay only the required minimum payment, interest will be charged to your Account <b>from the end of the promotional period</b> on any remaining promotional plan balance. This estimated  monthly payment reflects the amount due if your previous balance is zero; any additional purchases will increase your minimum payment. See your credit card agreement for additional minimum payment information.
  </p>
  <p>
  <b>Promotional Rate Plans, Low APR Equal Payment Plans</b> as referred to in the Comenity Capital Bank account agreement. This estimated monthly payment reflects the promotional plan balance divided by the number of months in the promotional period, rounded up to the nearest dollar. If you pay this amount each month, you should pay off the promotional plan purchase amount within the promotional period. The promotional period may end prior to the due date of the final billing period; see statement for promotional plan details. This estimated monthly payment will be the same as the minimum payment reflected on your monthly statement. This estimated monthly payment reflects the amount due if your previous balance is zero; any additional purchases will increase your minimum payment. Your Tuition Solution Credit Accounts are issued by Comenity Capital Bank.
  </p>
  `,
  FIXED_RATE_MONTHLY: "<p>The actual minimum payment due will be calculated as the greater of (a) $5 or (b) 4% of the New Balance shown on your statement (rounded to the nearest $1).</p>",
  calculator: { ...supPlacementDisclosures },
  selectPlan: { ...supPlacementDisclosures },
  addTransDisclosures: {
    ...changeDisclosures,
    PAYMENT_DETAIL_NO_INTEREST_PLAN: paymentDetailNoInterest,
    PAYMENT_DETAIL_PROMOTIONAL_RATE_PLAN: paymentDetailPromotionalRate
  },
  addTransDisclosuresV2: {
    PRE_QUALIFY: "<p><strong><u>Prequalify:</u></strong> A soft inquiry is used to check your eligibility which will not affect your credit score.</p>",
    ALL_PROMOTIONAL_PLANS: "<p><strong>*<u>All Promotional Plans:</u></strong> Valid for single transaction only. As of 4/2025, terms for new Accounts: Purchase APR of 32.99%. Minimum interest charge is $3 in any billing period in which interest is due. A fee of $2.99 per month may apply for each paper billing statement mailed to you. You can avoid this fee by enrolling in paperless statements. Existing Cardholders: see your credit card agreement for Account terms.</p>",
    PAYMENT_DETAIL_NO_INTEREST_PLAN: "<p><sup>1</sup><strong><u>Suggested Payment Details:</u></strong> The suggested monthly payment requires placing your purchase on a promotional credit plan, and it may be higher than the required minimum monthly payment on your billing statement. The following assumptions were made regarding the suggested monthly payment: it equals the purchase amount (excluding taxes and shipping costs) divided by the number of months in the promotional period, rounded up to the nearest dollar; you make 6 or 12 equal payments during the promotional period; you make all payments on time; and you currently do not and will not have any other balance on your Account during the promotional period.<sup>2</sup></p>",
    NO_INTEREST_PLAN: "<p><sup>2</sup><strong><u>Promotional Credit Plan Details:</u></strong> Waived Interest, Payment Required. No Interest for 6, or 12 months. After that, purchase APR of 32.99%. On purchases of $499 for 6 months, or $999 for 12 months made with your Lending Club Patient Solutions Credit Card. Interest will be charged to your Account from the end of the promotional period on any remaining promotional plan balance. <strong>Minimum payments are required for each Credit Plan. Minimum payments are NOT guaranteed to pay the promotional plan balance within the promotional period.</strong>*</p>",
    PAYMENT_DETAIL_PROMOTIONAL_RATE_PLAN: "<p><sup>3</sup><strong><u>Suggested Payment Details:</u></strong> The suggested monthly payment requires placing your purchase on a promotional credit plan with equal monthly payments. The following assumptions were made regarding the suggested monthly payment: it equals the purchase amount (excluding taxes and shipping costs) divided by the number of months in the promotional period, rounded up to the nearest dollar; you make 24, 36, 48, or 60 equal payments during the promotional period; and you make all payments on time.<sup>4</sup></p>",
    PROMOTIONAL_RATE_PLAN: "<p><sup>4</sup><strong><u>Promotional Credit Plan Details:</u></strong> Low APR, Equal Payment. 17.90, 18.90, 19.90, or 20.90% APR for 24, 36, 48 or 60 months. After that, purchase APR of 32.99%. On purchases of $1000 or more for 24 months, $4000 or more for 36 months, $6,000 or more for 48 months, and $8,000 or more for 60 months made with your Lending Club Patient Solutions Credit Card. Interest will be charged to your Account at the reduced APR from the purchase date through the end of the promotional period. After the promotional plan expiration date, the purchase APR will apply. Minimum payments are required for each Credit Plan.*</p>",
    DISCLAIMER: "<p>Credit Account offers are subject to credit approval. <br />Your Tuition Solution Credit Accounts are issued by Comenity Capital Bank.</p>"
  },
  disclosuresNew
};

